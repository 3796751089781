import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "POWER",
        para: "2 - AA household batteries power the WSPER device. We log device heartbeats* which includes the battery voltage and other vital device parameters and alert you when it's time to change the batteries and if the device is not connected to the LTE Network.  "
    },
    {
        id: 2,
        title: "CONNECTION",
        para: "WSPER is connected to the Global LTE-M network which is provides increased device coverage indoors, and in underground or semi-underground locations. No smartphone app, WIFI, Bluetooth or NFC connection required. "
    },
    {
        id: 3,
        title: "CONSTRUCTION",
        para: "IP54 casing protection (Protected from limited dust ingress. Protected from water spray from any direction.) The device measures under 5 inches (117mm) long by 1.5 inches wide (40mm) and 1 inch tall (26.5). can be installed with velcro, single mounting screw, or by a lanyard. Two screws hold tha back cover/battery compartment."
    },
    {
        id: 4,
        title: "PANIC MODE",
        para: "In times of urgency, each press of the panic button triggers an alert to our monitoring service provider, Noonlight. Swiftly, they initiate immediate communication with the authorities and also reach out to the designated device alarm contact. This feature proves invaluable for businesses situated in high-crime areas. Particularly beneficial for high-risk establishments dealing with vulnerable demographics such as children or the elderly, the panic button ensures a rapid and efficient response to potential emergencies."
    },
    {
        id: 5,
        title: "TEMPERATURE MODE",
        para: "Link our temperature probe to monitor the temperatures of your fridge or freezer, receiving instant alerts when the temperature enters the danger zone. Operating on battery power and the LTE-M network, this feature is ideal for situations with extreme weather conditions or when you're away, where power and Wi-Fi connectivity may be unreliable. Enjoy peace of mind, as WSPER ensures immediate alerts the moment any issues arise."
    },
    {
        id: 6,
        title: "SECURE DOOR MODE",
        para: "Link our door opening sensor to receive alerts whenever a door is opened. You have the flexibility to define the specific time frame within which the door should remain closed, and we'll promptly notify you if it's triggered. This feature is particularly well-suited for applications like cash safes, gun safes, and secure doors. Whether you're concerned about the well-being of elderly parents and want to be alerted if a door is opened at 2 am, or you own a rental/vacation home and seek a foolproof guarantee of being notified about any door openings, our system has you covered. "
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Features"
                    title="THE WSPER DEVICE FEATURES"
                    description="Explore the WSPER device features below. WSPER is perfect for business, rental properties, vacation homes, schools, and so many more."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;