import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import {Helmet} from 'react-helmet'


const PrivacyPolicy = () => {

    return (
        <>
            <Helmet> 
                <title>"Privacy Policy" </title>
            </Helmet>
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This Privacy policy was published on May 4th, 2023.</h5>
                                </div>
                                <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from getwsper.com (“the “Site”). </p>

<h4>Personal information we collect </h4>

<p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”. </p>

<h4>We collect Device Information using the following technologies:</h4>

<p>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href='http://www.allaboutcookies.org'>http://www.allaboutcookies.org</a>. 
“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. 
“Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site. 
Additionally when you make a purchase or attempt to make a purchase through  the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, PayPal), email address, phone number and false alarm pin. We refer to this information as “Order Information”. </p> 

<p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information. </p>

<h4>How do we use your personal information? </h4>

<p>We use the Order Information that we collect generally to fulfill any orders placed through  the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:</p>

<ul>
<li>Communicate with you. </li>
<li>Screen our orders for potential risk or fraud.</li>
<li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services. </li>
</ul>

<p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns). </p>

<p>When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.</p>

<p>When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.</p>

<p>Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.</p>

<p>Text marketing (if applicable): With your permission, we may send text messages about our store, new products, and other updates. Updates include Checkout Reminders. Webhooks will be used to trigger the Checkout Reminders messaging system.</p>

 

<h4>Sharing you personal Information </h4>

<p>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Stripe to power our online store––you can read more about how Stripe uses your Personal Information here: <a href='https://stripe.com/privacy'>https://stripe.com/privacy</a>. We also use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: <a href='https://www.google.com/intl/en/policies/privacy/'>https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a>. </p>

<p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights. </p>

<h4>Behavioral advertising</h4> 

<p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at <a href='http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work'>http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.</p>

<p>You can opt out of targeted advertising by using the links below: </p>

<p>Facebook: <a href='https://www.facebook.com/settings/?tab=ads'>https://www.facebook.com/settings/?tab=ads</a></p>
<p>Google: <a href='https://www.google.com/settings/ads/anonymous '>https://www.google.com/settings/ads/anonymous </a></p>
<p>Bing:<a href='https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads'>https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads </a></p>
<p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: <a href='http://optout.aboutads.info/'>http://optout.aboutads.info/.</a></p> 

<h4>“Wsper” Device</h4>

<p>Wsper collects location data to enable safety features On-Call Rep (24/7) and Location Sharing even when the device is in sleep mode or not in use.</p>

<h4>Do not track </h4>

<p>Please note that we do not alter the Site’s data collection and use practices when we see a Do Not Track signal from your browser. </p>

<h4>Your rights </h4>

<p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. </p>

<p>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through getwsper.com the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States. </p>

<h4>Data retention </h4>

<p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p> 

<h4>Changes </h4>

<p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. </p>

<h4>Minors </h4>

<p>The Site is not intended for individuals under the age of 13.</p> 

<h4>Contact us </h4>

<p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by email at support@getwsper.com or by mail using the details provided below:  </p>

<p>Wsper LLC Attn: Privacy Compliance Officer

New Orleans, LA 70115

United States</p>
</div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;