import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import { TypeAnimation } from 'react-type-animation';


const AboutFour = () => {
  
    
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">No app Needed</span>
                            <h2 className="title">Always Connected <TypeAnimation sequence={[
    // Same String at the start will only be typed once, initially
    'EVERY Second',
    1000,
    'EVERY Minute',
    1000,
    'EVERY Hour',
    1000,
    'EVERY Day',
    1000,
    'EVERY Week',
    1000,
    'EVERY Year',
    1000,
  ]}
  speed={40}
  style={{ fontSize: '1em' }}
  repeat={Infinity}
/></h2>
                            <p>WSPER devices are connected to the LTE-M network. LTE-M provides increased coverage indoors, and in underground or semi-underground locations. No WIFI, Bluetooth, NFC needed.</p>
                            <p>WSPER works without any smart phone apps or website dashboards. WSPER can be used in 3 individual modes: Panic button, temperature, and secure door monitoring</p>
                            <Link to="/checkout">
                    <Button className="axil-btn btn-fill-primary btn-large">Purchase Now</Button>
                </Link>
                        </div>
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="48" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Devices monitored</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="1500" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Heartbeats Tracked*</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;