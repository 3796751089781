import React, {useRef, useState} from 'react';
import { useSearchParams } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const FormOne = () => {

    const form = useRef();

    const [ result ] = useState(false);

    const [searchParams] = useSearchParams();
    const imei = searchParams.get("imei") ? searchParams.get("imei") : ""

    const submitForm = (e) => {
        e.preventDefault();
        let target   = e.target;

        fetch("https://api.getwsper.com/activate", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
            imei: target.imei.value,
            name: target.device_name.value
        }),
        })
        .then((res) => 
            window.location.replace("/")
        )
    }

    return (
        <form ref={form} onSubmit={submitForm} className="axil-contact-form">
        <Form.Group as={Col} controlId="formGridDeviceName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="device_name" placeholder="Device Name" required />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridDeviceImei">
            <Form.Label>IMEI</Form.Label>
            <Form.Control type="text" name="imei" placeholder="IMEI" value={imei} required disabled />
        </Form.Group>
        <br />
        <Form.Group as={Col}>
            <Button type='submit' className="axil-btn btn-fill-primary btn-fluid btn btn-primary" name="submit-btn">Activate Device</Button>
        </Form.Group>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default FormOne;