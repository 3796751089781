import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import PricingData from "../../data/pricing/PricingData.json";
import { FaCheck} from "react-icons/fa";

const getPriceingData = PricingData;

const billed = [
    {
        id: 1,
        label: "Billed Monthly"
    }/*,
    {
        id: 2,
        label: "Billed Yearly"
    }*/
]

const PricingSingle = () => {


    const [isActive, setActive] = useState("");

    useEffect(() => {
        setActive(billed[0].label.toLowerCase());
		
    }, []);


    const handleChange = (e) => {
        setActive(e.target.textContent.toLowerCase());
        
    };


    return (
        <>
            <div className="pricing-billing-duration" id="pricing-table">
                <ul>
                    {billed.map((data) => (
                        <li className="nav-item" key={data.id}>
                            <button onClick={handleChange} className={`nav-link ${data.label.toLocaleLowerCase() === isActive ? "active": ""}`}>{data.label}</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="row single">
                {getPriceingData.map((data, index) => (
                    <div className="col-lg-4" key={index}>
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                                <h3 className="title">{data.title}</h3>
                                <span className="subtitle">{data.subtitle}</span>
                                <div className="price-wrap">
                                    <div className="yearly-pricing">
                                        <span className="amount">{billed[0].label.toLowerCase() === isActive ? data.mprice : data.yprice }</span>
                                        <span className="duration">{billed[0].label.toLowerCase() === isActive ? data.mduration : data.yduration }</span>
                                    </div>
                                </div>
                                <div className="pricing-btn">
                                    <Link to="/checkout">
                                        <Button className="axil-btn btn-fill-primary btn-large">Get Protected Today<br />Order NOW</Button>
                </Link>
                                </div>
                            </div>
                            <div className="pricing-body">
                                <ul className="list-unstyled">
                                    {data.facility.map((data, index) => (
                                        <li key={index}><FaCheck /> {data}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PricingSingle;