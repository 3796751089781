import React from 'react';
import Nav from '../common/header/Nav';

  const CallbackPage = () => {
  return (
    <div className="page-layout">
      <Nav />
      <div className="page-layout__content" />
    </div>
  );
};

export default CallbackPage;