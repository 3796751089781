import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
//import SEO from '../common/SEO';
import { Helmet } from "react-helmet";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOne from "../component/process/ProcessOne";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import PricingOne from "../component/pricing/PricingSingle";
import FaqOne from "../component/faq/FaqOne";
import MarqueeClientLogos from "../component/common/MarqueeClientLogos";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>
          WSPER - Business Alerting Device with Cellular Connection and Alarm
          Center Monitoring
        </title>
        <meta
          name="description"
          content="WSPER is a LTE battery powered Alaerting Device. Keeping employees safe and connected to law enforcement when they need it most."
        />
        <meta
          name="keywords"
          content="panic button, cellular connection, property awareness, security, door alarm sensor, temperture alarm sensor, WSPER"
        />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "WSPER",
              "url": "https://www.getwsper.com",
              "logo": "https://getwsper.com/images/logo.svg",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-504-617-2924",
                "contactType": "Customer Service"
              }
            }
          `}
        </script>

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "GETWSPER - Business Panic Button",
              "image": "https://getwsper.com/images/logo.svg",
              "description": "Cellular connected Panic Button, Door Alarm and Tempeture Alarm for business.",
              "brand": {
                "@type": "Brand",
                "name": "WSPER"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://www.getwsper.com",
                "priceCurrency": "USD",
                "price": "59.99",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
              }
            }
          `}
        </script>
      </Helmet>

      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Protection that's ALWAYS ON, ALWAYS CONNECTED"
          paragraph="WSPER is a LTE battery powered alerting device. 3 modes: Panic button, door opening, temperature safety. Safety alarms for business, schools, and the recreational world."
          styleClass="thumbnail-4"
          mainThumb="https://getwsper.com/images/banner/banner-thumb-3.png"
        />
        <MarqueeClientLogos/>
        <AboutFour />
        <AboutThree />
        <ProcessOne />
        <PricingOne />
        <FaqOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AboutUs;
