import React, { useState } from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const Portal = () => {
    const [isLoading, setIsLoading] = useState(true); // State to track loading state

    // Function to handle iframe onload event
    const handleIframeLoad = () => {
        setIsLoading(false); // Set loading state to false when iframe is loaded
    };

    return (
        <>
            <SEO title="Portal" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <div className="section-padding">
                    <div className="container frame">
                        {/* Conditional rendering of loading spinner */}
                        {isLoading && (
                            <div className="loading-spinner">
                                Loading... {/* Replace with your spinner component or text */}
                            </div>
                        )}

                        <iframe
                            title="Client Dashboard"
                            src="https://getwsper.retool.com/embedded/public/cf3fac5e-e194-4c12-b53f-3c6626938dc9"
                            frameBorder="0"
                            seamless="seamless"
                            style={{
                                display: isLoading ? 'none' : 'block', // Hide iframe when loading
                                width: '100%',
                                height: '100vh',
                                marginTop: '3vh',
                            }}
                            onLoad={handleIframeLoad} // Call function when iframe is loaded
                        ></iframe>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default Portal;
