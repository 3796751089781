import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
//import SEO from '../common/SEO';
import { Helmet } from "react-helmet";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOneDoor from "../component/process/ProcessOneDoor";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import PricingOne from "../component/pricing/PricingSingle";
import FaqOne from "../component/faq/FaqOne";

const DoorAlarm = () => {
  return (
    <>
      <Helmet>
        <title>
          WSPER - LTE Door Alarm Sensor. 24/7 alarm station Monitoring. Always
          ON, Always Connected.
        </title>
        <meta
          name="description"
          content="Secure Your Entryways: Always Vigilant, Always Protected"
        />
        <meta
          name="keywords"
          content="door alarm, cellular connection, battery powered, solink, envysion, property awareness, WSPER"
        />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "WSPER",
              "url": "https://www.getwsper.com",
              "logo": "https://getwsper.com/images/logo.svg",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-504-617-2924",
                "contactType": "Customer Service"
              }
            }
          `}
        </script>

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "GETWSPER - Business Door Alarm Sensor",
              "image": "https://getwsper.com/images/logo.svg",
              "description": "Cellular connected Door Alarm for business.",
              "brand": {
                "@type": "Brand",
                "name": "WSPER"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://www.getwsper.com",
                "priceCurrency": "USD",
                "price": "59.99",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
              }
            }
          `}
        </script>
      </Helmet>

      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Secure Your Entryways: Always Vigilant, Always Protected"
          paragraph="Enhance your security with WSPER's Door Alarm Mode. This LTE battery-powered Panic Button utilizes a magnetic sensor to detect door openings and closings, providing real-time alerts for unauthorized access. With instant notifications, businesses can swiftly respond to potential threats, ensuring overall workplace safety. WSPER guarantees that help is always accessible, giving you peace of mind for your organization’s security needs."
          styleClass="thumbnail-4"
          mainThumb="https://getwsper.com/images/banner/banner-thumb-3.png"
        />
        <AboutFour />
        <AboutThree />
        <ProcessOneDoor />
        <PricingOne />
        <FaqOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default DoorAlarm;
