import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
//import SEO from '../common/SEO';
import { Helmet } from "react-helmet";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOneTemp from "../component/process/ProcessOneTemp";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import PricingOne from "../component/pricing/PricingSingle";
import FaqOne from "../component/faq/FaqOne";

const TempAlarm = () => {
  return (
    <>
      <Helmet>
        <title>WSPER Temperature Alarm Mode</title>
        <meta
          name="description"
          content="WSPER's Temperature Alarm Mode offers proactive monitoring for sensitive environments. This innovative LTE battery-powered Panic Button alerts employees when temperature thresholds are exceeded, helping to prevent equipment damage or inventory loss. By integrating temperature monitoring with emergency alerts, WSPER enhances operational efficiency and safety. Keep your business running smoothly with WSPER's temperature alarm capabilities."
        />
        <meta
          name="keywords"
          content="tempature sensor, cellular connection, battery powered, solink, envysion, property awareness, WSPER"
        />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "WSPER",
              "url": "https://www.getwsper.com",
              "logo": "https://getwsper.com/images/logo.svg",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-504-617-2924",
                "contactType": "Customer Service"
              }
            }
          `}
        </script>

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "GETWSPER - Business Temperture sensor",
              "image": "https://getwsper.com/images/logo.svg",
              "description": "Cellular connected Panic Button, Door Alarm and Tempeture Alarm for business.",
              "brand": {
                "@type": "Brand",
                "name": "WSPER"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://www.getwsper.com",
                "priceCurrency": "USD",
                "price": "59.99",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
              }
            }
          `}
        </script>
      </Helmet>

      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Protection that's ALWAYS ON, ALWAYS CONNECTED"
          paragraph="WSPER's Temperature Alarm Mode offers proactive monitoring for sensitive environments. This innovative LTE battery-powered Panic Button alerts employees when temperature thresholds are exceeded, helping to prevent equipment damage or inventory loss. By integrating temperature monitoring with emergency alerts, WSPER enhances operational efficiency and safety. Keep your business running smoothly with WSPER's temperature alarm capabilities."
          styleClass="thumbnail-4"
          mainThumb="https://getwsper.com/images/banner/banner-thumb-3.png"
        />
        <AboutFour />
        <AboutThree />
        <ProcessOneTemp />
        <PricingOne />
        <FaqOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TempAlarm;
