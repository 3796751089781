import React, { useState } from "react"; // Import React and useState
import { Link } from "react-router-dom";
import {
  /*FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, */ FaEnvelopeOpen,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";

//import { slugify } from '../../utils';

const getServiceData = ServiceData;
const showThanksPopup = () => {
  // Show thanks popup window
  // You can use a modal, alert, or any other method to display the popup
  alert("Thanks for subscribing!");
};

const FooterOne = ({ parentClass }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const onesignalPayload = {
      properties: {
        // You can customize the properties as needed
        last_active: Date.now(),
        tags: {
          type: "newsletter",
        },
      },
      identity: {
        // You can set external_id based on your requirement
        external_id: email,
      },
      subscriptions: [
        {
          type: "Email",
          token: email,
          enabled: true,
        },
      ],
    };

    try {
      const response = await fetch(
        "https://api.onesignal.com/apps/d18a60bd-ffab-4e13-9ce2-d883b095d6fe/users",
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic NmU0ODdlMzgtOTA2NS00MGNkLTliMTctM2VhNGQxY2E0ZDRk",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(onesignalPayload),
        }
      );
      const data = await response.json();

      if (response.ok) {
        console.log("User added to OneSignal");
        // Handle success, such as showing a success message
        // Clear the form
        document.getElementById("emailInput").value = "";
        // Show thanks popup window
        showThanksPopup();
      } else {
        console.error("Failed to add user to OneSignal", data);
        // Handle failure, such as showing an error message
      }
    } catch (error) {
      console.error("Error adding user to OneSignal:", error);
      // Handle error, such as showing an error message
    }
  };

  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          {/*<div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><Link to="https://facebook.com/"><FaFacebookF /></Link></li>
                            <li><Link to="https://twitter.com/"><FaTwitter /></Link></li>
                            <li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>
                            <li><Link to="https://www.linkedin.com/"><FaLinkedin /></Link></li>
                            <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>
                            <li><Link to="https://vimeo.com/"><FaVimeoV /></Link></li>
                            <li><Link to="https://dribbble.com/"><FaDribbble /></Link></li>
                            <li><Link to="https://www.behance.net/"><FaBehance /></Link></li>
                        </ul>
    </div>*/}
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <p>
                    Have questions? Enter your email and we will get back to you
                    asap.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <span className="mail-icon">
                        <FaEnvelopeOpen />{" "}
                      </span>
                      <input
                        id="emailInput"
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button className="subscribe-btn" type="submit">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Solutions</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 7).map((data, index) => (
                          <li key={index}>
                            {/*<Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>*/}
                            <li>{data.title}</li>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resourses</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/case-study"}>Case Studies</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Portfolio</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                                </div>*/}
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {/*<li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>*/}
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                            Terms of Use
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="mailto:support@getwsper.com">GETWSPER</a>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                      Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
