import { useEffect } from "react";
import OneSignal from "react-onesignal";

function InitializeOneSignal() {
  useEffect(() => {
    OneSignal.init({ appId: "d18a60bd-ffab-4e13-9ce2-d883b095d6fe" }).then(
      () => {
        OneSignal.Slidedown.promptPush();
        // do other stuff
      }
    );
  }, []);

  return null; // This component doesn't render anything visible
}

export default InitializeOneSignal;
