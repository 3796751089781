import React, { useEffect, useState } from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const Checkout = () => {
    const [isLoading, setIsLoading] = useState(true); // State to track loading state

    useEffect(() => {
        const iframe = document.querySelector('iframe[title="Checkout"]');
        const loadingSpinner = document.getElementById("loading-spinner");

        const initializeIframe = () => {
            // Show loading spinner
            loadingSpinner.style.display = "flex";

            if (iframe) {
                // Wait for the iframe to load
                iframe.addEventListener("load", () => {
                    // Hide loading spinner
                    setIsLoading(false);

                    // Show the iframe
                    iframe.style.display = "block";
                });
            }
        };

        initializeIframe();

        // Clean up the event listener when the component unmounts
        return () => {
            if (iframe) {
                iframe.removeEventListener("load", () => {});
            }
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <>
            <SEO title="Checkout" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <div className="section-padding">
                    <div className="container frame">
                        {/* Conditional rendering of loading spinner */}
                        {isLoading && (
                            <div id="loading-spinner" className="loading-spinner">
                                Loading... {/* Replace with your spinner component or text */}
                            </div>
                        )}

                        <iframe
                            title="Checkout"
                            src="https://getwsper.retool.com/embedded/public/bbe4b30b-0e6d-44d1-b6f8-5e11e6ba8718"
                            frameBorder="0"
                            seamless="seamless"
                            style={{
                                display: isLoading ? 'none' : 'block', // Hide iframe when loading
                                width: '100%',
                                height: '100vh',
                                marginTop: '3vh',
                                overflow: 'hidden'
                            }}
                        ></iframe>
                    </div>
                </div>

                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default Checkout;
