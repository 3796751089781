import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";


const Heartbeat = () => {
  // Sample steps with text and image URLs
  const steps = [
    {
      title: "Step 1: Power off the device",
      text: "To reset the WSPER device, start by turning it off. Remove the batteries or press the power button until the device shuts down.",
      imgSrc: "path_to_image/power_off.jpg", // Replace with your image path
      altText: "Powering off the WSPER device",
    },
    {
      title: "Step 2: Remove and reinsert the batteries",
      text: "Open the battery compartment and take out the batteries. Wait for 10 seconds and reinsert them. Ensure they are inserted correctly.",
      imgSrc: "path_to_image/battery_replacement.jpg", // Replace with your image path
      altText: "Removing and reinserting batteries",
    },
    {
      title: "Step 3: Hold down the reset button",
      text: "Locate the reset button on the device and press it for 5-10 seconds until you see the LED light flash.",
      imgSrc: "path_to_image/reset_button.jpg", // Replace with your image path
      altText: "Holding down the reset button",
    },
    {
      title: "Step 4: Wait for the device to reconnect",
      text: "The WSPER device will now attempt to reconnect to the network. This can take up to 1 minute. The LED light will blink to indicate successful connection.",
      imgSrc: "path_to_image/reconnect.jpg", // Replace with your image path
      altText: "Waiting for network reconnection",
    },
    {
      title: "Step 5: Verify the connection on the dashboard",
      text: "Login to your WSPER portal and check if the device shows as connected under 'Device Status'. If not, repeat the steps.",
      imgSrc: "path_to_image/verify_dashboard.jpg", // Replace with your image path
      altText: "Verifying connection in WSPER portal",
    },
  ];

  return (
    <>
<SEO title="How to Reset WSPER Device" />
<ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
    <div className="guide-container">
      <h1>How to Reset Your WSPER Device to Reconnect to the Network</h1>
      <div className="steps">
        {steps.map((step, index) => (
          <div key={index} className="step">
            <h2>{step.title}</h2>
            <p>{step.text}</p>
            <img src={step.imgSrc} alt={step.altText} className="step-image" />
          </div>
        ))}
      </div>
    </div>
    <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
      </>
  );
};

export default Heartbeat;
