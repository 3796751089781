import React from "react";
import { Link } from "react-router-dom";
import BlogData from "../../../data/blog/BlogData.json";
import generateSlug from "../../../utils/generateSlug"; // Import the generateSlug function

const WidgetPost = () => {
  // Sort the blog posts by post_date in descending order
  const sortedBlogData = BlogData.sort(
    (a, b) => new Date(b.post_date) - new Date(a.post_date)
  );

  return (
    <div className="post-list-wrap">
      {sortedBlogData.slice(0, 3).map((data, index) => {
        const slug = generateSlug(data.title); // Generate slug for each blog post

        return (
          <div className="single-post" key={index}>
            <div className="post-thumbnail">
              <Link to={process.env.PUBLIC_URL + `/blog-details/${slug}`}>
                <img
                  src={`${process.env.PUBLIC_URL}/${data.thumb}`}
                  alt="Blog"
                />
              </Link>
            </div>
            <div className="post-content">
              <h6 className="title">
                <Link to={process.env.PUBLIC_URL + `/blog-details/${slug}`}>
                  {data.title}
                </Link>
              </h6>
              <ul className="blog-meta list-unstyled">
                <li>{data.post_date}</li>
                <li>{data.read_time}</li>
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WidgetPost;
