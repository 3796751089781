import React from "react";
import WidgetPost from "./widget/WidgetPost";
import WidgetCategory from "./widget/WidgetCategory";
import {
  FaFacebookF,
  //FaTwitter,
  FaInstagram,
  //FaLinkedinIn,
  //FaPinterest,
  //FaYoutube,
} from "react-icons/fa";

const BlogSidebar = () => {
  return (
    <div className="axil-sidebar">
      <div className="widget widget-categories">
        <h4 className="widget-title">Categories</h4>
        <WidgetCategory />
      </div>
      <div className="widget widge-social-share">
        <div className="blog-share">
          <h5 className="title">Follow:</h5>
          <ul className="social-list list-unstyled">
            <li>
              <a href="https://www.facebook.com/getwsper/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/getwsper?igsh=MW1tZXJkam9ocGozMw==">
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="widget widget-recent-post">
        <h4 className="widget-title">Recent post</h4>
        <WidgetPost />
      </div>
      {/*<div className="widget widget-banner-ad">
                <Link to="#">
                    <img src={process.env.PUBLIC_URL + "/images/banner/widget-banner.png"} alt="banner" />
                </Link>
            </div>*/}
    </div>
  );
};

export default BlogSidebar;
