import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
//import SEO from '../common/SEO';
import { Helmet } from "react-helmet";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOnePanic from "../component/process/ProcessOnePanic";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import PricingOne from "../component/pricing/PricingSingle";
import FaqOne from "../component/faq/FaqOne";

const PanicAlarm = () => {
  return (
    <>
      <Helmet>
        <title>
          WSPER - LTE Panic button. 24/7 alarm station Monitoring. Always ON,
          Always Connected.
        </title>
        <meta
          name="description"
          content="WSPER is an LTE battery-powered Panic Button that enhances workplace safety and security. In Panic Alarm Mode, employees can quickly alert law enforcement in emergencies with a simple press of the button. This critical safety feature ensures immediate assistance, enabling rapid response during urgent situations. Keep your workplace secure and connected with WSPER, the ultimate panic alarm solution."
        />
        <meta
          name="keywords"
          content="panic button, cellular connection, battery powered, solink, envysion, property awareness, WSPER"
        />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "WSPER",
              "url": "https://www.getwsper.com",
              "logo": "https://getwsper.com/images/logo.svg",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-504-617-2924",
                "contactType": "Customer Service"
              }
            }
          `}
        </script>

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "GETWSPER - Business Panic Button",
              "image": "https://getwsper.com/images/logo.svg",
              "description": "Cellular connected Panic Button, Door Alarm and Tempeture Alarm for business.",
              "brand": {
                "@type": "Brand",
                "name": "WSPER"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://www.getwsper.com",
                "priceCurrency": "USD",
                "price": "59.99",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
              }
            }
          `}
        </script>
      </Helmet>

      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Protection that's ALWAYS ON, ALWAYS CONNECTED"
          paragraph="WSPER is an LTE battery-powered Panic Button that enhances workplace safety and security. In Panic Alarm Mode, employees can quickly alert law enforcement in emergencies with a simple press of the button. This critical safety feature ensures immediate assistance, enabling rapid response during urgent situations. Keep your workplace secure and connected with WSPER, the ultimate panic alarm solution."
          styleClass="thumbnail-4"
          mainThumb="https://getwsper.com/images/banner/banner-thumb-3.png"
        />
        <AboutFour />
        <AboutThree />
        <ProcessOnePanic />
        <PricingOne />
        <FaqOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PanicAlarm;
