import React from "react";
import Marquee from "react-fast-marquee";

const MarqueeClientLogos = () => {
  return (
    <div className="marquee-containerLogo">
      {/* Scrolling Marquee with custom spacing between phrases */}
      <Marquee
        gradient="true"
        gradientColor="white" // White background color in RGB format
        gradientWidth={200} // Width of the fade effect
        speed={30}
        autoFill={true}
      >
        <img
          src={
            process.env.PUBLIC_URL + "/images/testimonial/DollarTreeLogo.svg"
          }
          alt="Dollar Tree Logo"
          class="marquee-image"
        ></img>
        <img
          src={
            process.env.PUBLIC_URL +
            "/images/testimonial/TheKentCompaniesLogo.png"
          }
          alt="Kent Companies Logo"
          class="marquee-image"
        ></img>
        <img
          src={process.env.PUBLIC_URL + "/images/testimonial/niosSpaLogo.webp"}
          alt="Nios Spa Logo"
          class="marquee-image"
        ></img>
        <img
          src={
            process.env.PUBLIC_URL + "/images/testimonial/PGASuperStoreLogo.svg"
          }
          alt="PGA Logo"
          class="marquee-image"
        ></img>
        <img
          src={process.env.PUBLIC_URL + "/images/testimonial/VerdilogoBig.svg"}
          alt="PGA Logo"
          class="marquee-image"
        ></img>
        <img
          src={process.env.PUBLIC_URL + "/images/testimonial/solinkLogo.png"}
          alt="SoLink Logo"
          class="marquee-image"
        ></img>
        <img
          src={process.env.PUBLIC_URL + "/images/testimonial/NoonLightLogo.png"}
          alt="Noonlight Logo"
          class="marquee-image"
        ></img>
      </Marquee>
    </div>
  );
};

export default MarqueeClientLogos;
