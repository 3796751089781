import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ limage, dimage, simage }) => {
  return (
    <Link to={process.env.PUBLIC_URL + "/"}>
      <img
        className="light-version-logo"
        src="https://getwsper.com/images/logo.svg"
        alt="logo"
      />
      <img
        className="dark-version-logo"
        src="https://getwsper.com/images/logo.svg"
        alt="logo"
      />
      <img
        className="sticky-logo"
        src="https://getwsper.com/images/logo.svg"
        alt="logo"
      />
    </Link>
  );
};

export default Logo;
