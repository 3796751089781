import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BlogData from "../data/blog/BlogData.json";
import BlogSidebar from "../component/blog/BlogSidebar";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { FaPlay, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import FsLightbox from "fslightbox-react";
import Slider from "react-slick";
import BlogListOne from "../component/blog/BlogListOne";
import generateSlug from "../utils/generateSlug";
import ResponsiveTable from "../component/blog/responsiveTable"; // Import the ResponsiveTable component

const BlogDetails = () => {
  const { slug } = useParams(); // Use slug from URL parameters
  const [toggler, setToggler] = useState(false);

  // Find the blog post by matching the slug
  const detailsBlog = BlogData.find(
    (blog) => generateSlug(blog.title) === slug
  );

  if (!detailsBlog) {
    return <div>Blog post not found</div>; // Handle cases where the blog post isn't found
  }

  function SlickNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  function SlickPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }

  const slideSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
  };

  return (
    <>
      <Helmet>
        <title>{detailsBlog.title}</title>
        <meta name="description" content={detailsBlog.excerpt} />
        <meta property="og:title" content={detailsBlog.title} />
        <meta property="og:description" content={detailsBlog.excerpt} />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/${detailsBlog.large_thumb[0]}`} // Assuming the first image is the main image
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <SEO title={detailsBlog.title} />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <div className="section-padding-equal">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-8">
                <div className="single-blog">
                  <div className="single-blog-content blog-grid">
                  <h1 className="title h2" dangerouslySetInnerHTML={{__html: detailsBlog.title}}></h1>
                    <div className="post-thumbnail">
                      {Array.isArray(detailsBlog.large_thumb) ? (
                        <Slider {...slideSettings} className="slick-arrow-nav">
                          {detailsBlog.large_thumb.map((data, index) => (
                            <div className="slide-item" key={index}>
                              <img
                                src={`${process.env.PUBLIC_URL}/${data}`}
                                alt="Blog"
                              />
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/${detailsBlog.large_thumb}`}
                          alt="Blog"
                        />
                      )}

                      {detailsBlog.format === "video" &&
                        detailsBlog.video_link && (
                          <>
                            <div className="popup-video">
                              <button
                                className="play-btn"
                                onClick={() => setToggler(!toggler)}
                              >
                                <FaPlay />
                              </button>
                            </div>
                            <FsLightbox
                              toggler={toggler}
                              sources={[detailsBlog.video_link]}
                              key={detailsBlog.id}
                            />
                          </>
                        )}
                    </div>
                    <div className="author">
                      <div className="author-thumb">
                        <img
                          src={`${process.env.PUBLIC_URL}/${detailsBlog.author_avatar}`}
                          alt="Blog Author"
                        />
                      </div>
                      <div className="info">
                        <ul className="blog-meta list-unstyled">
                          <li>{detailsBlog.post_date}</li>
                          <li>{detailsBlog.read_time}</li>
                        </ul>
                      </div>
                    </div>
                    {detailsBlog.body.map((content, index) => {
                      if (typeof content === "string") {
                        return (
                          <div
                            key={index}
                            dangerouslySetInnerHTML={{ __html: content }}
                          />
                        );
                      } else if (content.type === "table") {
                        return (
                          <ResponsiveTable
                            key={index}
                            headers={content.table_data.headers}
                            rows={content.table_data.rows}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}

                    <div className="row">
                      {detailsBlog.features_img.map((img, i) => (
                        <div className="col-6" key={i}>
                          <div className="featured-img">
                            <img
                              src={process.env.PUBLIC_URL +"/"+ img}
                              alt="Blog"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <BlogAuthor data={detailsBlog} />*/}
              </div>
              <div className="col-lg-4">
                <BlogSidebar />
              </div>
            </div>
          </div>
        </div>

        <div className="section section-padding-equal pt-0 related-blog-area">
          <div className="container">
            <div className="section-heading heading-left">
              <h3 className="title">Related Post</h3>
            </div>
            <div className="row g-0">
              <BlogListOne colSize="col-xl-6" itemShow="2" />
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default BlogDetails;
