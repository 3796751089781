import React, { useState, useEffect, useRef } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {PaymentElement} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51MpMJtANRx8YKAvE6iCCOViW5xbzihoEX1IhrsfSKj9dk14VcVCIFYx632rRI2P4JUwMmaZuvRxoQp9w9ScuuAjF00Fm9JwJR3');

const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    const [clientSecret] = useState("");
    const [qty, setQty] = useState(1)
    const [needsUpdate, setNeedsUpdate] = useState(true)
    const [devices, setDevices] = useState([])

    const ref = useRef();

    const updateQuantity = () => {
        var quant = parseInt(ref.current.value)
        if (quant < 1) {
            quant = 1
        }
        setQty(quant)
        setNeedsUpdate(true)

        return qty
    };

    const updateDevices = () => {
        let devices_list = [];
        for (let i = 1; i <= qty; i++) {
            let formGridDevicePhone = "formGridDevicePhone_" + i
            let device_phone = "device_phone_" + i
            let formGridDevicePin = "formGridDevicePin_" + i
            let pin = "pin_" + i
            let formGridDeviceAddress = "formGridDeviceAddress_" + i
            let line1 = "line1_" + i
            let formGridDeviceAddress2 = "formGridDeviceAddress2_" + i
            let line2 = "line2_" + i
            let formGridDeviceCity = "formGridDeviceCity_" + i
            let city = "city_" + i
            let formGridDeviceState = "formGridDeviceState_" + i
            let state = "state_" + i
            let formGridDeviceZip = "formGridDeviceZip_" + i
            let zip = "zip_" + i
            devices_list.push(
                <div key={i}>
                    <Row className="mb-3">
                        <h4 className='text-danger'>Device {i} Information</h4>
                        <Form.Group as={Col} controlId={formGridDevicePhone}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="phone" name={device_phone} placeholder="Phone" required />
                        </Form.Group>
                        <Form.Group as={Col} controlId={formGridDevicePin}>
                            <Form.Label>Pin</Form.Label>
                            <Form.Control type="number" name={pin} placeholder="Pin" required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId={formGridDeviceAddress}>
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" name={line1} placeholder="Address" required />
                        </Form.Group>
                        <Form.Group as={Col} controlId={formGridDeviceAddress2}>
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control type="text" name={line2} placeholder="Address 2" />
                        </Form.Group>
                        <Form.Group as={Col} controlId={formGridDeviceCity}>
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" name={city} placeholder="City" required />
                        </Form.Group>
                        <Form.Group as={Col} controlId={formGridDeviceState}>
                            <Form.Label>State</Form.Label>
                            <Form.Select name={state}>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} controlId={formGridDeviceZip}>
                            <Form.Label>Zip</Form.Label>
                            <Form.Control type="text" name={zip} placeholder="Zip" required />
                        </Form.Group>
                    </Row>
                </div>
            );
        }
        setDevices(devices_list)
        setNeedsUpdate(false)

        return devices;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("E: ", e)

        var qty = parseInt(ref.current.value)

        let target   = e.target;
        var address = []
        var device_phone_each = 0
        var device_pin_each = 0
        var device_line1_each = ""
        var device_line2_each = ""
        var device_city_each = ""
        var device_state_each = ""
        var device_zip_each = ""
        for (var i = 1; i <= qty; i++) {
            for (let j = 1; j < target.length; j++) {

                var elem = target.elements[j].getAttribute("name")
                if (elem === String("device_phone_" + i)) {
                    device_phone_each = target.elements[j].value
                } else if (elem === String("pin_" + i)) {
                    device_pin_each = target.elements[j].value
                } else if (elem === String("line1_" + i)) {
                    device_line1_each = target.elements[j].value
                } else if (elem === String("line2_" + i)) {
                    device_line2_each = target.elements[j].value
                } else if (elem === String("city_" + i)) {
                    device_city_each = target.elements[j].value
                } else if (elem === String("state_" + i)) {
                    device_state_each = target.elements[j].value
                } else if (elem === String("zip_" + i)) {
                    device_zip_each = target.elements[j].value
                }
            }

            var addy = {
                name: e.target.owner_name.value,
                phone: device_phone_each,
                pin: device_pin_each,
                line1: device_line1_each,
                line2: device_line2_each,
                city: device_city_each,
                state: device_state_each,
                zip: device_zip_each,
            }
            address.push(addy)
        }

        // Create PaymentIntent as soon as the page loads
        fetch("https://api.getwsper.com/checkout", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
            devices: address,
            owner_email: e.target.owner_email.value,
            owner_name: e.target.owner_name.value,
            owner_phone: e.target.owner_phone.value,
            quantity: qty, 
            recur: "monthly" 
        }),
        })
        .then((res) => 
            window.location.replace(res.headers.get("location"))
        )
        
    };

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    useEffect(()=>{        
        if (needsUpdate === true) {
            updateDevices()
        }
    })
    
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton>
                <h4>SHOPPING CART</h4>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <Form onSubmit={handleSubmit}>
            {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <PaymentElement />
                </Elements>
            )}
            <Row className="mb-3">
                <h4 className='text-danger'>Order Information</h4>
                <Form.Group as={Col} controlId="formGridQuantity">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control type="number" name="quantity" min={1} defaultValue="1" ref={ref} onChange={updateQuantity} required />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <h4 className='text-danger'>Owner Information</h4>
                <Form.Group as={Col} controlId="formGridOwnerName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" name="owner_name" placeholder="Name" required />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridOwnerPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="phone" name="owner_phone" placeholder="Phone" required />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridOwnerEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="owner_email" placeholder="Email" required />
                </Form.Group>
            </Row>
            {devices}
            <Button type='submit' className="axil-btn btn-fill-primary btn-large btn btn-primary" >Checkout</Button>
            </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;