import React from "react";
import Marquee from "react-fast-marquee";

const MarqueeNav = () => {
  return (
    <div className="marquee-container">
      {/* Scrolling Marquee with custom spacing between phrases */}
      <Marquee
        gradient={true}
        gradientColor={[0,0,0]} // White background color in RGB format
        gradientWidth={300} // Width of the fade effect
        speed={60}
        style={{ color: "#f4475c" }} // Text color
      >
        <span className="marquee-item">DISTRESS SIGNAL</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">EMERGENCY ASSISTANCE</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">SAFE MONITORING</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">DOOR ALARM</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">TEMPERATURE ALARM</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">PANIC ALARM</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">GLOBAL COVERAGE</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">STAND-ALONE</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">ALWAYS ON</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">24/7 MONITORING</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">BATTERY POWERED</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">NO WI-FI</span> 
        <span className="marquee-item">♦️</span> 
        <span className="marquee-item">LTE-M</span>
      </Marquee>
    </div>
  );
};

export default MarqueeNav;
