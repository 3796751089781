import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const Nav = () => {
  return (
    
      <nav className="mainmenu-nav">
        <ul className="mainmenu">
          {/* New Modes Menu Item */}
          <li className="menu-item-has-children menu-item-modes">
            <span>Modes</span>
            <ul className="submenu">
              <li>
                <Link to="/panic-alarm">Panic Alarm</Link>
              </li>
              <li>
                <Link to="/door-alarm">Door Alarm</Link>
              </li>
              <li>
                <Link to="/temperature-alarm">Temperature Alarm</Link>
              </li>
            </ul>
          </li>
          <li className="menu-item-has-children">
            <Link to="/checkout">Purchase</Link>
          </li>
          <li className="menu-item-has-children">
            <Link to="/demo">Panic Demo</Link>
          </li>
          <li className="menu-item-has-children">
            <Link to="/#faq-area">FAQ</Link>
          </li>
          <li className="menu-item-has-children">
            <Link to="/blog">Blog</Link>
          </li>
          <li className="menu-item-has-children">
            <Link to="/portal">Portal</Link>
          </li>
        </ul>
      </nav>
   
  );
};

export default Nav;
